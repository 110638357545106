<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("BANNER") }}
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-img
            height="180"
            :src="item.src"
            @click="handleShowUploader"
          >
            <v-btn fab v-if="!showUploader" @click="handleShowUploader">
              <v-icon>mdi-image</v-icon>
            </v-btn>
          </v-img>

          <upload-Area
            v-if="showUploader"
            inputIdent="upload-input-banners"
            :show="showUploader"
            identity="banners"
            @uploaded="handleUploaded"
          />
        </v-card-text>
        <v-card-text>
          <!-- <v-text-field
            label="Image Source"
            v-model="item.src"
            type="text"
            :error-messages="errors && errors.src ? errors.src : []"
          ></v-text-field> -->

          <v-text-field
            label="English Title"
            v-model="item.title"
            type="text"
            :error-messages="errors && errors.title ? errors.title : []"
          ></v-text-field>

          <v-text-field
            label="हिन्दी शीर्षक"
            v-model="item.title_hi"
            type="text"
            :error-messages="errors && errors.title_hi ? errors.title_hi : []"
          ></v-text-field>

          <v-text-field
            label="URL"
            v-model="item.url"
            type="text"
            :error-messages="errors && errors.url ? errors.url : []"
          ></v-text-field>

          <v-text-field
            label="URI"
            v-model="item.uri"
            type="text"
            :error-messages="errors && errors.uri ? errors.uri : []"
          ></v-text-field>

          <v-text-field
            label="Type"
            v-model="item.type"
            type="text"
            :error-messages="errors && errors.type ? errors.type : []"
          ></v-text-field>

          <v-card-actions>
            <v-text-field
              label="Start At"
              v-model="item.start_at"
              type="text"
              :error-messages="errors && errors.start_at ? errors.start_at : []"
            ></v-text-field>

            <v-spacer></v-spacer>
            
            <v-text-field
              label="End At"
              v-model="item.end_at"
              type="text"
              :error-messages="errors && errors.end_at ? errors.end_at : []"
            ></v-text-field>
          </v-card-actions>



          <selector-language
            :select="item.language"
            @selected="(abbr) => (item.language = abbr)"
          />
        </v-card-text>


        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn text @click="$router.push({ name: 'dashboard-banners' })">{{
              $lang("CANCEL")
            }}</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="item.id" color="red" outlined @click="handleDelete()">
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import SelectorLanguage from "@/app/dashboard/prep/modules//SelectorLanguage";
import { axios } from "@/plugins/axios";
import UploadArea from "@/app/dashboard/auth/modules/UploadArea.vue";

import {
  youTubeIdFromLink,
  validateYouTubeUrl,
} from "@/helpers/utils";

export default {
  name: "dashboard-banners-create",
  components: {
    LayoutCenter,
    SelectorLanguage,
    UploadArea
  },
  data() {
    return {
      item: {
        id: null,
        title: "",
        title_hi: "",
        src: null,
        language: null,
      },
      loading: false,
      showUploader: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    handleShowUploader() {
      this.showUploader = true;
      // this.item.src = null;
    },
    handleUploaded(url) {
      this.item.src = url;
      this.showUploader = false;
    },
    handleDelete() {
      if (
        !confirm(
          `Are you sure to delete ${
            this.item.title ? this.item.title : this.item.id
          }?`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      this.loding = true;
      return axios
        .delete(`/banners/${this.item.id}`)
        .then((res) => {
          this.$router.replace({ name: "dashboard-banners" });
          return Promise.resolve(res);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchItem(id) {
      return axios
        .get(`banners/${id}`)
        .then((res) => {
          this.item = res.data;
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-banners" });
        });
    },
    doSubmit() {
      if (!this.item.src || this.item.src.trim().length < 11) {
        alert("Image source is required.");
        return;
      }
      if (this.$route.params.id) {
        return axios
          .post(`banners/${this.$route.params.id}`, this.item)
          .then((res) => {
            // this.item = res.data;
            // this.$router.replace({
            //   name: "dashboard-banners-create",
            //   params: { id: this.$route.params.id },
            // });
            this.$router.replace({ name: "dashboard-banners" });
          });
      } else {
        return axios
          .post(`banners`, this.item)
          .then((res) => {
            this.$router.replace({
              name: "dashboard-banners-create",
              params: { id: res.data.data.id },
            });
          })
          .catch(() => {
            // this.$router.replace({ name: "dashboard-banners" });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
    },
    "item.ytid"(val) {
      if (validateYouTubeUrl(val)) {
        this.loading = true;
        let id = youTubeIdFromLink(val);
        console.log("youTubeIdFromLink ID", id);
        setTimeout(() => {
          this.item.ytid = id;
          this.loading = false;
        }, 600);
      }
      this.item.ytid = val;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>
